import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack
} from '@chakra-ui/react'
import { DownArrow, UpArrow } from '@olagg/ui-kit';

import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const NavMenu = ({ buttonText, menuOptions }) => {
  const [menuActive, setMenuActive] = useState(false)
  const {t} = useTranslation()

  return (
    <Stack direction={'row'} spacing={4}>
      <Box>
        <Popover
          trigger={'hover'}
          placement={'bottom-start'}
          onOpen={() => setMenuActive(true)}
          onClose={() => setMenuActive(false)}
        >
          <PopoverTrigger>
            <Button
              height="48px"
              px='18px'
              py='15.5px'
              borderRadius='6px'
              color='#fff'
              fontSize='16px'
              fontWeight={menuActive ? 'bold' : '400'}
              bg={menuActive ? '#0C0324' : '#2B2640'}
              _hover={{ bg: '#0C0324', fontWeight: 'bold' }}
              rightIcon={menuActive ? <UpArrow style={{ marginLeft: '10px' }} /> : <DownArrow style={{ marginLeft: '10px' }} />}
              marginRight={'6px'}
            >
              {buttonText}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            border={0}
            bg={'#0C0324'}
            p={4}
            borderRadius={'6px'}
            width={'auto'}
          >
            {menuOptions.map((option, index) => (
              option?.to ? (
                <Link to={option.to} key={index}>
                  <Button
                    padding={'0px'}
                    alignSelf={'start'}
                    bg={'#0C0324'}
                    color={'#fff'}
                    _hover={{ bg: '#0C0324', fontWeight: 'bold' }}
                    fontSize={'16px'}
                    fontWeight={'400'}
                    leftIcon={option.icon}
                    _active={{ bg: '#0C0324', fontWeight: 'bold' }}
                  >
                    {option.text}
                    {option.new && (
                      <Flex sx={{
                        background: 'linear-gradient(90deg, #4E10FF 0%, #AB17DF 100%)',
                        borderRadius: '12px',
                        padding: '2px 8px',
                        alignItems: 'center'
                      }} ml={'auto'} fontSize='11px' fontWeight={700} textTransform={'uppercase'}>{t('navbar.myAccountNav.new')}</Flex>
                    )}
                  </Button>
                </Link>
              ) : (
                <Button
                  padding={'0px'}
                  alignSelf={'start'}
                  key={index}
                  bg={'#0C0324'}
                  color={'#fff'}
                  _hover={{ bg: '#0C0324', fontWeight: 'bold' }}
                  fontSize={'16px'}
                  fontWeight={'400'}
                  leftIcon={option.icon}
                  _active={{ bg: '#0C0324', fontWeight: 'bold' }}
                  onClick={option.onClick}
                >
                  {option.text}
                </Button>
              )
            ))
            }
          </PopoverContent>
        </Popover>
      </Box>
    </Stack>
  )

  // return (
  //   <Menu>
  //     {({ isOpen }) => (
  //       <>
  //         <MenuButton
  //         style={{...style}}
  //         isActive={isOpen}
  //         height="48px"
  //         px='16px'
  //         py='15.5px'
  //         borderRadius='6px'
  //         color='#fff'
  //         fontSize='16px'
  //         fontWeight={isOpen ? "bold" : "400"}
  //         bg={isOpen ? '#0C0324' : '#2B2640'}
  //         _hover={{ bg: '#0C0324', fontWeight: 'bold' }}
  //       >
  //       <Flex alignItems="center">
  //         {buttonText}
  //         {
  //           isOpen ? (
  //             <UpArrow style={{ marginLeft: '10px' }}/>
  //           ) : (
  //             <DownArrow style={{ marginLeft: '10px' }}/>
  //           )
  //         }
  //       </Flex>
  //     </MenuButton>
  //     <MenuList
  //       bg='#0C0324'
  //       borderWidth='0px'
  //     >
  //       {menuOptions.map((option, index) => (
  //         <MenuItem
  //           key={index}
  //           icon={option.icon}
  //           onClick={option.onClick}
  //           bg='#0C0324'
  //           color='#fff'
  //           _hover={{ fontWeight: 'bold' }}
  //           fontSize='16px'
  //           trigger='hover'
  //           >
  //           {option.text}
  //         </MenuItem>
  //       ))}
  //     </MenuList>
  //       </>
  //     )}
  //   </Menu>
  //)
}

export default NavMenu